import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Constants from '../services/constants';

@Injectable({
  providedIn: 'root'
})
export class ChantierService {

  constructor(private http: HttpClient) { }

  /**
  * Récupération des numeros de chantiers
  * 
  * @returns Observable with token d'authentification
  */
  getNumChantiers(token) {
    //let head = new HttpHeaders();
    //headers.append('token', token);
    let headers = new HttpHeaders({ 'token': token });
    return this.http.get(Constants.API_BASE_URL + Constants.API_NUM_CHANTIER, { headers });
  }

  /**
  * Récupération des détails du chantier correspondant
  * @param {string} numeroschantier numéro du chantier
  * @returns Observable with token d'authentification
  */
  getDetailsChantier(token, numeroschantier) {
    let headers = new HttpHeaders({ 'token': token });
    let postData = {
      "numChantier": numeroschantier,
    }
    //const requestOptions = new RequestOptions({ headers: headers });
    return this.http.post(Constants.API_BASE_URL + Constants.API_DET_CHANTIER, postData, { headers });
  }

  /**
  * Ajout d'une saisie pour le chantier/user/journée
  * @param {string} numeroschantier numéro du chantier
  * @param {string} date date de la saisie
  * @param {string} duree duree de la saisie
  * @param {string} justificatif justificatif (non obligatoir)
  * @returns Observable with token d'authentification
  */
  postChantier(token, ID, numeroschantier, date, duree, justificatif) {
    let headers = new HttpHeaders({ 'token': token });
    console.log(token);
    let postData = {
      "ID": ID,
      "numChantier": numeroschantier,
      "date": date,
      "duree": duree,
      "justificatif": justificatif
    }
    console.log(postData);
    return this.http.post(Constants.API_BASE_URL + Constants.API_SAISIE, postData, { headers });
  }
}
