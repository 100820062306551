import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  constructor(
    private alertCtrl: AlertController,
    private storage: Storage,
    private router: Router) { }

  public deconnexion() {
    this.alertCtrl.create({
      message: 'Êtes-vous sûr de vouloir vous déconnecter ?',
      buttons: [
        {
          text: 'Oui',
          role: 'cancel',
          handler: data => {
            this.storage.set('token', null);
            this.storage.set('user', null);
            this.router.navigateByUrl('/connexion');
          }
        },
        {
          text: 'Retour'
        },
      ]
    }).then((res) => {
      res.present();
    });

  }

  public backToConnect() {
    this.alertCtrl.create({
        message: 'Vous êtes déconnecté, vous allez être redirigé vers la page de connexion.',
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              this.storage.set('token', null);
              this.router.navigateByUrl('/connexion');
            }
          }
        ]
    }).then((res) => {
      res.present();
    });
  }
}
