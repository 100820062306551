export const API_BASE_URL = 'https://myartimadmin.artim-menuisier.fr'; //https://artim.a3web.fr
export const API_AUTH = '/services/authentification';
export const API_MAIL = '/services/sendmail';
export const API_NUM_CHANTIER = '/services/numeroschantier';
export const API_DET_CHANTIER = '/services/detailschantier';
export const API_SAISIE = '/services/saisie';
export const API_INCIDENT = '/services/incident';
export const API_JOURNEE = '/services/journee';
export const API_CHANTIER_DATE = '/services/chantierspardate';
export const API_SUPP_SAISIE = '/services/suppsaisiejournee';
export const API_RECAP_SEMAINE = '/services/recap-semaine';
export const API_RESTAURANTS = '/services/restaurants';
