import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Constants from '../services/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string;
  constructor(private http: HttpClient) { }

  /**
  * Auth de l'utilisateur
  * 
  * @param {string} identifiant identifiant du user
  * @param {string} mdp mot de passe du user
  * @returns Observable with token d'authentification
  */
  postUser(identifiant, mdp) {
    //AJOUT CONTENT-TYPE
    // let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*' });
    //const requestOptions = new RequestOptions({ headers: headers });
    let postData = {
      "identification": identifiant,
      "motdepasse": mdp
    }
    return this.http.post(Constants.API_BASE_URL + Constants.API_AUTH, postData);


    // .subscribe(data => {
    //   console.log(data);
    // }, error => {
    //   console.log(error);
    // });
  }

  /**
   * Reset du password
   * 
   * @param mail Mail de l'utilisateur
   */
  forgotPwd(mail) {
    let postData = {
      "mail": mail,
    }
    return this.http.post(Constants.API_BASE_URL + Constants.API_MAIL, postData);
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }


}
